import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import 'aos/dist/aos.css';
import ContactQuery from '@components/contact-query/contact-query';
import { graphql } from 'gatsby';

function getParameterByName(name) {
  if (typeof window !== 'undefined') {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}

const Contact = (props) => {
  const officecontact = getParameterByName('contact');
  const [officeDetails, setOfficeDetails] = useState(null);

  const Page = props?.data?.strapiArticles;

  const TeamMember = props?.data?.strapiArticles?.Team_Members;

  const classAttribute = `contact-landing-wrap ${Page?.Layout || ''} ${Page?.Custom_CSS_Class || ''}`;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedOfficeDetails = JSON.parse(window.localStorage.getItem('office_details'));
      setOfficeDetails(storedOfficeDetails);
    }
  }, []);

  return (
	  <Layout classAttribute={classAttribute} pageType="contact" popularSearch={Page?.Select_Popular_Search}>
		<ContactQuery PageData={Page} officecontact={officecontact}	 officeinfo = {officeDetails}  team={TeamMember} />		
      </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
query ContactQuery {

    strapiArticles(id: {eq: "Articles_5fc09d8229e1721c1dc7f0b8"}) {
		Alias
		id
		Meta_Description
    	Meta_Title
		Pagename
		Layout
		Select_Popular_Search
		Team_Members {
          Designation
          Email
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    	}
	}
}
`