import React from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { useLocation } from '@reach/router';
import { gql, useQuery } from '@apollo/client';
import { parse } from "query-string"
import SEO from "../seo";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";
import GetinTouch from "../forms/get-in-touch";
import OfficeContactForm from '../forms/office-contact';
import ContactBox from '../contact-box/contact-box';
import './styles/_index.scss';

const GET_OFFICE_DETAILS = gql`
  query GetOfficeDetails($URL: String!) {
    offices(where: { URL: $URL }) {
      Title
      Email
      URL
      Address
      Tile_Image {
        url
      }
      Team_Members {
        Name
        Email
        Phone
        Designation
        Image {
          url
        }
      }
    }
  }
`;


const ContactQuery = (props) => {   
    const location = useLocation();
    const searchParams = parse(location.search) // => {init: "true"}   
    var office = searchParams.office
   
    const { loading, error, data } = useQuery(GET_OFFICE_DETAILS, {
        variables: { URL: office },
    });
    
    
    const officeDetails = data?.offices[0];

    const metaTitle = officeDetails ? `${officeDetails.Title} Estate Agents | General Enquiry` : props?.PageData?.Meta_Title;
    const metaDescription = officeDetails ? `Contact ${officeDetails.Title} Estate Agents for any general property enquiry and we will be happy to help you.` : props?.PageData?.Meta_Description;

    const pageTitle =  officeDetails ?`Contact ${officeDetails.Title}`: "General Enquiry";
    return (
        <>        
        <SEO title={metaTitle} description={metaDescription} />
        <Breadcrumbs alias={officeDetails ? 'our-offices' : undefined} />
        <div className="contact-form-page spacing-bottom-only">
            <div className="page-header spacing-64">                
                <Container>
                    <h1 className="spacing-bottom-24">{pageTitle}</h1>
                    <div className="contact-intro-text">                        
                        <p>Please complete the form below and we will be in touch.</p>
                    </div>
                </Container>
            </div>
            <div className="page-contact-form">
                <Container>
                    <Row>
                        <Col md="12" lg={{span: 7}}>
                            <div className="contact-form">
                                {officeDetails ? (
                                    <OfficeContactForm to_email_id={officeDetails.Email} />
                                ) : (
                                    <GetinTouch />
                                )}
                            </div>
                        </Col>
                        <Col md="12" lg={{ span: 4, offset: 1 }}>
                            {officeDetails && officeDetails !== null && (
                                <div className="office-contact-box">
                                    <div className="office-contact-box__image">
                                        <img src={officeDetails?.Tile_Image && officeDetails?.Tile_Image?.url} alt={officeDetails.Title} />
                                    </div>
                                    <div className="office-contact-box__content">
                                        <div className="office-box-address spacing-bottom-32">
                                            <h4 className="h5" >{officeDetails?.Title}</h4>
                                            <p>{officeDetails?.Address}</p>
                                        </div>
                                        <div className="office-team-contact">
                                            {officeDetails?.Team_Members && officeDetails?.Team_Members.map((data, key) => (
                                                <ContactBox
                                                    key={key}
                                                    name={data.Name}
                                                    email={data.Email}
                                                    phone={data.Phone}
                                                    position={data.Designation}
                                                    link={data.Calendly_Link}
                                                    linkText="Video Call"
                                                    teamimage={data.Image && data.Image.url}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>        
        </>
    );
}
export default ContactQuery;

