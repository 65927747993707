import React,{useEffect,useState} from 'react';
import { Col, Row } from "react-bootstrap";
import Img from 'gatsby-image';
import ImgBox from '@components/img-box/img-box'
import MemberForm from "@components/forms/member-form"
import Modal from 'react-bootstrap/Modal'

/**
 * Assets
 */

import './styles/_index.scss';

const ContactBox = ({image, teamimage, phone, link, email, name, position, linkText, layout}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [teamid, setteamid] = useState(false);

  const handleShow = (event) => {
    setteamid(event);
    setShow(true);
}

let AOS;
useEffect(() => {
  const AOS = require("aos");
  AOS.init({
    once: true,
    disable: 'mobile'
  });
}, [])

    return (
        <div className="contact-box">
            <Row className="align-items-center">
                <Col xs="auto">
                {teamimage && teamimage !== null ? 
                    <ImgBox image={teamimage}></ImgBox> :
                    <Img fluid={image} alt="avatar" />
                }
                </Col>
                <Col>
                    <>
                    {layout==='property-details'?<div className='contact-box-text text-20-16 bold'>Ready to take the next step? <a className='text-20' href="javascript:;" onClick={ () => handleShow(email)}>Book an appointment</a> <span>with our broker.</span></div>
                      
                  :
                      <>
                        <h5 className='text-20 bold'>{name}</h5>
                        {position ? <h6>{position}</h6> : ''}
                        <div className="links">
                            {phone && 
                            <>
                            <a className='text-20' href={`tel:${phone}`}>{phone}</a> <span className='text-20 divid'> / </span>
                            </>
                            }
                            {link ?
                            <a className='text-20' href={`${link}/`}>{linkText}</a> :
                            <a className='text-20' href="javascript:;" onClick={ () => handleShow(email)}>Email</a>
                            }
                        </div>
                      </>
                      }
                            <Modal
                            show={show}
                            centered={true}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-90w modal-form team-contact-form">

                            <Modal.Header closeButton className="contact-close-btn memberPopup">
                            <Modal.Title className="w-100 ">{name ? `CONTACT - ${name}` : "CONTACT"}
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <MemberForm to_email_id={email} formName={`Contact Person`} />
                            </Modal.Body>
                            </Modal>
                        </>
                </Col>
            </Row>
        </div>
    )
}

export default ContactBox;
